<div class="profile-container" *ngIf="profile">
  <div fxFlex="100" fxLayout="row">
    <div fxFlex="40" fxLayoutAlign="center center" class="p5">
      <img src="/assets/img/dummy-user.png" alt="" class="profile-image" />
    </div>
    <div fxFlex="60" fxLayout="column">
      <div fxFlex="100" fxLayout="column" fxLayoutAlign="center start">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon>assignment_ind</mat-icon>&nbsp;<b
            >{{ profile.first_name }} {{ profile.last_name }}</b
          >
        </div>
        <br />
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon>email</mat-icon>&nbsp;<i>{{ profile.email }}</i>
        </div>
      </div>
    </div>
  </div>
</div>
