import { Injectable, OnInit, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private snackBar: MatSnackBar, private ngZone: NgZone) {}

  alertError(message, duration = 4000, action = '') {
    this.ngZone.run(() => {
      this.snackBar.open(message, action, {
        duration: duration,
        panelClass: ['alert-error']
      });
    });
  }

  alertSuccess(message, duration = 3000, action = '') {
    this.ngZone.run(() => {
      this.snackBar.open(message, action, {
        duration: duration,
        panelClass: ['alert-success']
      });
    });
  }

  alertInfo(message, duration = 3000, action = '') {
    this.ngZone.run(() => {
      this.snackBar.open(message, action, {
        duration: duration
      });
    });
  }
}
