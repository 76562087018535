import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import { FlexLayoutModule } from "@angular/flex-layout";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatBadgeModule } from "@angular/material/badge";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepicker, MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatStepperModule } from "@angular/material/stepper";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatMenuModule } from "@angular/material/menu";
import { MatCardModule } from "@angular/material/card";
import { MatListModule } from "@angular/material/list";
import { MatButtonModule } from "@angular/material/button";
import { MatSelectModule } from "@angular/material/select";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTableModule } from "@angular/material/table";
import { MatRadioModule } from "@angular/material/radio";
import { AlertService } from "./services/alert.service";
import { ReversePipe } from "./pipes/array-inverse";
import { ProfileComponent } from "./modals/profile/profile.component";
import { StatComponent } from "./components/stat/stat.component";
import { GroupByPipe } from "./pipes/group-by.pipe";
@NgModule({ declarations: [ReversePipe, ProfileComponent, StatComponent, GroupByPipe],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // Modals
        ProfileComponent,
        // 3rd party
        FlexLayoutModule,
        // Material
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatListModule,
        MatButtonModule,
        MatSelectModule,
        MatExpansionModule,
        MatTableModule,
        MatRadioModule,
        MatDialogModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatNativeDateModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        NgxSpinnerModule,
        MatPaginatorModule,
        MatToolbarModule,
        MatStepperModule,
        MatBadgeModule,
        ReversePipe,
        StatComponent,
        GroupByPipe,
    ], imports: [CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // 3rd party
        FlexLayoutModule,
        // Material
        MatFormFieldModule,
        MatInputModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatListModule,
        MatButtonModule,
        MatSelectModule,
        MatExpansionModule,
        MatTableModule,
        MatRadioModule,
        MatDialogModule,
        MatSidenavModule,
        MatToolbarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatAutocompleteModule,
        MatToolbarModule,
        MatStepperModule,
        MatBadgeModule], providers: [AlertService, ReversePipe, GroupByPipe, provideHttpClient(withInterceptorsFromDi())] })
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [AlertService],
    };
  }
}
