import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/core/servicecs/auth.service";
import { User } from "src/app/api/models/user";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"]
})
export class ProfileComponent implements OnInit {
  profile: User;
  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.loadProfile();
  }
  loadProfile() {
    this.authService.getProfile().subscribe(
      response => {
        this.profile = response;
      },
      err => {
        throw err;
      }
    );
  }
}
