import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Router } from "@angular/router";
import { GlobalConfigService } from "./global-config.service";
import { HttpClient } from "@angular/common/http";
import { User } from "src/app/api/models/user";
import { Role } from "src/app/api/models/role";
import { Paging } from "src/app/api/models/paging";
import { Permission } from "src/app/api/models/permission";
@Injectable()
export class AuthService {
  currentUser: User;
  constructor(
    public jwtHelper: JwtHelperService,
    private router: Router,
    public http: HttpClient,
    private config: GlobalConfigService
  ) {
    // Initialize baseUrl here
    this.baseUrl = this.config.allSettings.apiBaseUrl;
  }
  private baseUrl: string;
  // ...
  public isAuthenticated(): boolean {
    const token = localStorage.getItem("token");
    // Check whether the token is expired and return
    // true or false
    return !this.jwtHelper.isTokenExpired(token);
  }
  login(email: string, password: string) {
    return this.http.post<any>(`${this.baseUrl}/auth/login`, {
      email,
      password,
    });
  }
  getProfile() {
    return this.http.post<User>(`${this.baseUrl}/auth/me`, {});
  }
  createRole(name) {
    return this.http.post<Role>(`${this.baseUrl}/auth/create-role`, name);
  }
  update(name: string, id: number) {
    return this.http.patch(`${this.baseUrl}/auth/update-role/${id}`, { name });
  }

  delete(id: number) {
    return this.http.delete(`${this.baseUrl}/auth/delete-role/${id}`);
  }
  assignRole(user_id, role) {
    return this.http.post(
      `${this.baseUrl}/auth/assign-role`,
      {
        user_id,
        role,
      },
      {
        responseType: "text",
      }
    );
  }
  removeUserRole(user_id, role) {
    return this.http.post(
      `${this.baseUrl}/auth/remove-role`,
      {
        user_id,
        role,
      },
      {
        responseType: "text",
      }
    );
  }
  assignPermission(name, permissions) {
    return this.http.post(
      `${this.baseUrl}/auth/assign-permission`,
      {
        name,
        permissions,
      },
      {
        responseType: "text",
      }
    );
  }
  removePermission(name, permission) {
    return this.http.post(
      `${this.baseUrl}/auth/remove-permission`,
      {
        name,
        permission,
      },
      {
        responseType: "text",
      }
    );
  }

  search(text: string) {
    return this.http.post(`${this.baseUrl}/auth/search-role`, {
      keyword: text,
    });
  }
  getAllRoles() {
    return this.http.get<Role[]>(`${this.baseUrl}/auth/all-roles`);
  }
  hasPermission(permissionId: number) {
    const user = this.getCurrentUser();
    let hasAccess = false;
    if (user) {
      user.roles.forEach((role) => {
        hasAccess = role.permissions.find((p) => p.id === permissionId) != null;
      });
    }
    return hasAccess;
  }
  getCurrentUser() {
    const user = JSON.parse(localStorage.getItem("currentUser")) as User;
    return user;
  }
  getPermissionByRole(id: number) {
    return this.http.get<Permission[]>(
      `${this.baseUrl}/auth/permissions/roles/${id}`
    );
  }
  getRoleByUserId(id: number) {
    return this.http.get<Role[]>(`${this.baseUrl}/auth/roles/user/${id}`);
  }
  getAllPermissions() {
    return this.http.get<Permission[]>(`${this.baseUrl}/auth/all-permissions`);
  }
  logout() {
    localStorage.clear();
    this.router.navigate(["/auth"]);
  }
}
